import React from "react"

import {
  Title,
  Subtitle,
  Section,
  Container,
  Field,
  Label,
  Control,
  Input,
  Icon,
  Help,
  Select,
  TextArea,
  Checkbox,
  Radio,
  Button,
  Columns,
  Column,
  FieldLabel,
  FieldBody,
  Image,
} from "bloomer"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import ContactForm from "../components/contact-form"

import DeliveryAddress from "../assets/delivery-address.svg"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <Header title="Contact"></Header>
    <Section>
      <Container>
        <Columns>
          <Column isSize="1/2">
            <ContactForm/>
          </Column>
          <Column isSize="1/2">
          <DeliveryAddress className="img-vect is-hidden-mobile" />
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)

export default ContactPage
