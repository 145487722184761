import React, { useState, useEffect } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"

import Recaptcha from "react-recaptcha"

function ContactForm() {
  return (
    <Formik
      initialValues={{ fullName: "", email: "", phone: "", company: "" }}
      validate={values => {
        const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        const phoneRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/g
        const errors = {}
        if (!values.fullName) {
          errors.fullName = "Please enter a name."
        } else if (values.fullName.length <= 1) {
          errors.fullName = "Too short!"
        }
        if (!values.email && emailRegex.test(values.email)) {
          errors.email = "Please enter a valid email address!"
        }
        if (!values.Phone && phoneRegex.test(values.phone)) {
          errors.phone = "Please enter a valid phone number."
        }
        return errors;
      }}
      onSubmit={values => {
        console.log(values);
      }}
    />
  )
}

export default ContactForm
